<template>
  <v-dialog
    :value="value"
    :max-width="maxWidth"
    @click:outside="handleClose"
    @keydown.esc="handleClose"
  >
    <v-card :loading="pending">
      <v-card-title class="text-h5">{{
        isEdit ? "Редактировать группу" : "Создать группу"
      }}</v-card-title>
      <v-card-text class="black--text">
        <validation-observer ref="form" tag="form" @submit.prevent>
          <div>
            <validation-provider
              v-slot="{ errors }"
              :rules="{ required: true, min: 3, max: 250 }"
              name="name"
              slim
            >
              <v-text-field
                v-model="form.name"
                label="Название группы*"
                :error-messages="errors"
                class="mb-3"
                outlined
              ></v-text-field>
            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              :rules="{ required: true, min: 3, max: 250 }"
              slim
              name="code"
            >
              <v-text-field
                v-model="form.code"
                label="Код*"
                :disabled="isEdit"
                :error-messages="errors"
                hint="Уникальный код группы. Служит для идентификации. Нельзя изменить после создания. Например: konkurs_talant"
                outlined
                persistent-hint
                class="mb-3"
              ></v-text-field>
            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              :rules="{
                min: 3,
                max: 500,
              }"
              slim
              name="description"
            >
              <v-textarea
                v-model="form.description"
                label="Описание группы"
                :error-messages="errors"
                hint="Пока не ясно где будет использоваться. Скорее для адиминистраторов"
                persistent-hint
                outlined
                class="mb-3"
              ></v-textarea>
            </validation-provider>

            <p>
              <strong>Дата создания достижений</strong> <br />
              Калькуляторы будет учитывать достижения,
              <strong>добавленные не позднее</strong>
              указанной даты. Не влияет на импортированные достижения.
            </p>

            <validation-provider
              v-slot="{ errors }"
              :rules="{
                required: true,
              }"
              slim
              name="achievement_created_limit"
            >
              <v-datetime-picker
                :key="`create-${key}`"
                v-model="form.achievement_created_limit"
                label="Укажите дату*"
                :text-field-props="{
                  'prepend-icon': 'mdi-calendar',
                  hint: `Время местное`,
                  'persistent-hint': true,
                  dense: true,
                  outlined: true,
                  'error-messages': errors,
                }"
                :time-picker-props="{
                  format: '24hr',
                }"
              >
                <template slot="dateIcon">
                  <v-icon>mdi-calendar-range</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>mdi-timer</v-icon>
                </template>
              </v-datetime-picker>
            </validation-provider>

            <p class="mt-6">
              <strong>Дата мероприятий</strong> <br />
              Калькуляторы будет учитывать достижения, полученные за
              мероприятия, <strong>начавшиеся</strong> не позднее указанной даты
            </p>

            <validation-provider
              v-slot="{ errors }"
              :rules="{
                required: true,
              }"
              slim
              name="event_start_limit"
            >
              <v-datetime-picker
                :key="`start-${key}`"
                v-model="form.event_start_limit"
                label="Укажите дату*"
                :text-field-props="{
                  'prepend-icon': 'mdi-calendar',
                  hint: `Время местное`,
                  'persistent-hint': true,
                  dense: true,
                  outlined: true,
                  clearable: true,
                  'error-messages': errors,
                }"
                :time-picker-props="{
                  format: '24hr',
                }"
              >
                <template slot="dateIcon">
                  <v-icon>mdi-calendar-range</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>mdi-timer</v-icon>
                </template>
              </v-datetime-picker>
            </validation-provider>
          </div>
        </validation-observer>

        <div v-if="error" class="error--text my-3">{{ error }}</div>
        <v-divider class="my-3"></v-divider>
      </v-card-text>
      <v-card-actions class="px-5 pb-4">
        <template v-if="!isEdit">
          <v-btn color="primary" :loading="pending" @click="handleCreate"
            >Создать</v-btn
          >
          <v-btn color="primary" outlined @click="handleClose">Отмена</v-btn>
        </template>
        <template v-else>
          <v-btn color="primary" :loading="pending" @click="handleUpdate"
            >Сохранить</v-btn
          >
          <v-btn color="primary" outlined @click="handleClose">Закрыть</v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { DEFAULT_DIALOG_WIDTH } from "@/constants";
import { nonFalsyEntries } from "@/utils";
const initialData = () => {
  return {
    key: 1,
    pending: false,
    error: "",
    form: {
      name: "",
      code: "",
      description: "",
      achievement_created_limit: null,
      event_start_limit: null,
    },
  };
};
export default {
  name: "GroupDialog",
  props: {
    value: Boolean,
    maxWidth: {
      type: String,
      default: DEFAULT_DIALOG_WIDTH,
    },
    group: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      ...initialData(),
    };
  },
  computed: {
    isEdit() {
      return !!this.group?.id;
    },
  },
  watch: {
    value(val) {
      const data = initialData();
      if (val) {
        const { group } = this;
        if (group) {
          // set data
          data.form.code = group.code;
          data.form.name = group.name;
          data.form.description = group.description;
          if (group.achievement_created_limit) {
            data.form.achievement_created_limit = new Date(
              group.achievement_created_limit
            );
          }

          if (group.event_start_limit) {
            data.form.event_start_limit = new Date(group.event_start_limit);
          }
        }
        data.key = Date.now();
        Object.assign(this.$data, data);
      } else {
        Object.assign(this.$data, {
          ...initialData(),
          key: Date.now(),
        });
      }
      this.$refs.form?.reset();
    },
  },
  methods: {
    handleClose() {
      this.$emit("input", false);
    },
    async handleUpdate() {
      const isValid = await this.$refs.form.validate();
      if (!isValid || !this.isEdit) {
        return;
      }
      this.pending = true;
      const payload = nonFalsyEntries(this.form);
      payload.id = this.group.id;
      delete payload.code;
      try {
        await this.$store.dispatch("updateGroup", payload);
        this.handleClose();
      } catch (e) {
        this.error = e.message;
      } finally {
        this.pending = false;
      }
    },
    async handleCreate() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        return;
      }
      this.pending = true;
      const payload = nonFalsyEntries(this.form);
      try {
        await this.$store.dispatch("createGroup", payload);
        this.handleClose();
      } catch (e) {
        this.error = e.message;
      } finally {
        this.pending = false;
      }
    },
  },
};
</script>

<style></style>
