<template>
  <div>
    <div>{{ group.name }}</div>
    <div>{{ group.description }}</div>
    <div>{{ group.code }}</div>
  </div>
</template>

<script>
export default {
  name: "GroupCard",
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
