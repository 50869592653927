<template>
  <v-container>
    <div class="mb-8">
      <h2 class="mb-2">Компетенции</h2>
      <v-btn color="primary" class="mr-2" @click="openCompetenceDialog(null)"
        >Создать компетенцию</v-btn
      >
      <v-btn color="primary" outlined @click="openGroupModal(null)"
        >Создать группу</v-btn
      >
    </div>
    <div v-if="error" class="my-2 error--text">
      <p>Не удалось получить список компетенций. {{ error }}</p>
    </div>

    <div>
      <v-sheet v-for="group in groupedList" :key="group.id" class="mb-6 pa-6">
        <div>
          <h3 v-if="group.id === 'ungrouped'">Компетенции без группы</h3>
          <div v-else-if="group.group">
            <div class="d-flex">
              <div class="flex-grow-1">
                <h3>{{ group.group.name }}</h3>
                <div>
                  Дата начала мероприятий:
                  {{
                    formatDate(group.group.event_start_limit) || "не указана"
                  }}
                </div>
                <div>
                  Дата создания достижений:
                  {{
                    formatDate(group.group.achievement_created_limit) ||
                    "не указана"
                  }}
                </div>

                <div v-if="group.group.description" class="mt-1">
                  {{ group.group.description }}
                </div>
              </div>
              <div class="flex-grow-0 flex-shrink-0">
                <v-hover v-slot="{ hover }">
                  <v-btn
                    :color="hover ? 'primary' : 'grey lighten-1'"
                    :title="`Редактировать группу ${group.group.name}`"
                    icon
                    @click.stop="openGroupModal(group.id)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </v-hover>

                <v-hover v-slot="{ hover }">
                  <v-btn
                    :color="hover ? 'error' : 'grey lighten-1'"
                    :title="`Удалить группу ${group.group.name}`"
                    icon
                    @click.stop="handleRemoveGroup(group.id)"
                  >
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </v-hover>
              </div>
            </div>
          </div>
        </div>

        <div v-if="group.list.length" class="mt-3">
          <v-simple-table class="mb-3">
            <template #default>
              <thead>
                <tr>
                  <th class="text-left" width="100">id</th>
                  <th class="text-left">Название компетенции</th>
                  <th class="text-left">Ограничивающий коэф.</th>
                  <th class="text-left">Код</th>
                  <th class="text-left">Мероприятие</th>
                  <th class="text-left">Показывать в ЛК</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in group.list"
                  :key="item.id"
                  :class="{ 'is-disabled': !item.is_active }"
                  @click="
                    $router.push({
                      name: 'competence_tracks',
                      params: { id: item.id },
                    })
                  "
                >
                  <td>{{ item.id }}</td>
                  <td>
                    {{ item.name }}
                  </td>
                  <td>
                    {{ item.limit_coefficient }}
                  </td>
                  <td>
                    {{ item.code }}
                  </td>
                  <td>{{ item.event_id }}</td>
                  <td>{{ item.is_active ? "Да" : "Нет" }}</td>
                  <td width="120px" class="text-right">
                    <v-hover v-slot="{ hover }">
                      <v-btn
                        :color="hover ? 'primary' : 'grey lighten-1'"
                        title="Редактировать компетенцию"
                        icon
                        @click.stop="openCompetenceDialog(item)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </v-hover>

                    <v-hover v-slot="{ hover }">
                      <v-btn
                        :color="hover ? 'error' : 'grey lighten-1'"
                        title="Удалить компетенцию"
                        icon
                        @click.stop="handleRemove(item.id)"
                      >
                        <v-icon>mdi-trash-can</v-icon>
                      </v-btn>
                    </v-hover>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>

        <div v-else>
          <p>В этой группе пока нет компетенций</p>
        </div>
      </v-sheet>
    </div>

    <competence-create-dialog
      v-model="createDialog"
      :competence="selectedCompetence"
    />
    <group-dialog v-model="groupModal" :group="currentGroup" />
  </v-container>
</template>

<script>
import { apiClient } from "@/api";
import dayjs from "@/plugins/dayjs";
// const DISPLAY_FORMAT = "DD-MM-YYYY HH:mm:ss";
import CompetenceCreateDialog from "@/components/dialogs/CompetenceCreateDialog";
import { GroupDialog } from "@/components/groups";

/**
 * @page Страница списка компетенций
 */
export default {
  name: "CompetenceList",
  components: {
    CompetenceCreateDialog,
    GroupDialog,
  },
  metaInfo: {
    title: "Компетенций конкруса",
  },
  data() {
    return {
      createDialog: false,
      pending: false,
      error: "",
      selectedCompetence: null,
      groupModal: false,
      currentGroup: null,
    };
  },
  computed: {
    list() {
      return this.$store.state.competences;
    },
    groups() {
      return this.$store.state.groups;
    },
    groupedList() {
      const groupAcc = Object.values(this.groups).reduce((acc, val) => {
        acc[val.id] = {
          id: val.id,
          group: val,
          list: [],
        };
        return acc;
      }, {});

      groupAcc.ungrouped = {
        id: "ungrouped",
        list: [],
      };

      const obj = this.list.reduce((acc, value) => {
        const groupId = value.group_id || "ungrouped";
        if (acc[groupId]) {
          acc[groupId].list.push(value);
        }
        return acc;
      }, groupAcc);

      if (obj.ungrouped.list.length === 0) {
        delete obj.ungrouped;
      }

      const sortedGroups = Object.values(obj).sort((a, b) => {
        return b.list.length - a.list.length;
      });

      for (const group in sortedGroups) {
        sortedGroups[group].list.sort((a, b) => {
          return b.is_active - a.is_active;
        });
      }

      return sortedGroups;
    },
  },
  created() {
    this.$store.dispatch("getAllTracks");
    this.getList();
  },
  methods: {
    async getList() {
      this.pending = true;
      try {
        await Promise.all([
          this.$store.dispatch("getAllGroups"),
          this.$store.dispatch("getAllCompetence"),
        ]);
      } catch (error) {
        this.error = error.message;
      }
      this.pending = false;
    },
    async handleRemoveGroup(id) {
      if (!id) return;
      const group = this.groupedList.find((item) => item.id === id);
      if (!group) return;
      if (group.list.length) {
        const confirm = await this.$root.$confirm(
          `Удалить группу «${group.group.name}»`,
          `Все компетенции входящие в эту группу, будут разгруппированы. Вы уверены, что хотите удалить группу «${group.group.name}»?`,
          {
            confirmText: "Да, удалить",
            rejectText: "Нет, отмена",
          }
        );
        if (!confirm) return;
      }
      try {
        await this.$store.dispatch("deleteGroup", id);
      } catch (error) {
        this.$toast(`Не удалось удалить группу. ${error.message}`, {
          type: "error",
        });
      }
    },
    async handleRemove(id) {
      if (!id) return;
      const confirm = await this.$root.$confirm(
        "Удалить компетенцию",
        "Это действие необратимо. Вы уверены что хотите удалить компетенцию?",
        {
          confirmText: "Да, удалить",
          rejectText: "Нет, отмена",
        }
      );
      if (!confirm) return;
      try {
        await apiClient({
          method: "DELETE",
          url: `/competences/${id}`,
        });
        this.$store.commit("DELETE_COMPETENCE", id);
      } catch (error) {
        this.$toast(`Не удалось удалить компетенцию. ${error.message}`, {
          type: "error",
        });
      }
    },
    openCompetenceDialog(comp) {
      this.selectedCompetence = comp || null;
      this.createDialog = true;
    },
    openGroupModal(groupId = null) {
      if (!groupId) {
        this.currentGroup = null;
        this.groupModal = true;
        return;
      }
      if (groupId in this.groups) {
        this.currentGroup = { ...this.groups[groupId] };
        this.groupModal = true;
        return;
      }
    },
    formatDate(date) {
      if (!date) return;
      return dayjs(date).format("LL");
    },
  },
};
</script>

<style scoped>
tr.is-disabled {
  opacity: 0.5;
}
</style>
