export const nonFalsyEntries = (obj) => {
  if (!obj || typeof obj !== "object") {
    throw new Error("nonFalsyEntries: obj must be an object");
  }
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value) {
      acc[key] = value;
    }
    return acc;
  }, {});
};
