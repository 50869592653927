var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"value":_vm.value,"max-width":_vm.maxWidth},on:{"click:outside":_vm.handleClose,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.handleClose.apply(null, arguments)}}},[_c('v-card',{attrs:{"loading":_vm.pending}},[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.isEdit ? "Редактировать группу" : "Создать группу"))]),_c('v-card-text',{staticClass:"black--text"},[_c('validation-observer',{ref:"form",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();}}},[_c('div',[_c('validation-provider',{attrs:{"rules":{ required: true, min: 3, max: 250 },"name":"name","slim":""},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticClass:"mb-3",attrs:{"label":"Название группы*","error-messages":errors,"outlined":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}])}),_c('validation-provider',{attrs:{"rules":{ required: true, min: 3, max: 250 },"slim":"","name":"code"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticClass:"mb-3",attrs:{"label":"Код*","disabled":_vm.isEdit,"error-messages":errors,"hint":"Уникальный код группы. Служит для идентификации. Нельзя изменить после создания. Например: konkurs_talant","outlined":"","persistent-hint":""},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}})]}}])}),_c('validation-provider',{attrs:{"rules":{
              min: 3,
              max: 500,
            },"slim":"","name":"description"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-textarea',{staticClass:"mb-3",attrs:{"label":"Описание группы","error-messages":errors,"hint":"Пока не ясно где будет использоваться. Скорее для адиминистраторов","persistent-hint":"","outlined":""},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})]}}])}),_c('p',[_c('strong',[_vm._v("Дата создания достижений")]),_vm._v(" "),_c('br'),_vm._v(" Калькуляторы будет учитывать достижения, "),_c('strong',[_vm._v("добавленные не позднее")]),_vm._v(" указанной даты. Не влияет на импортированные достижения. ")]),_c('validation-provider',{attrs:{"rules":{
              required: true,
            },"slim":"","name":"achievement_created_limit"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-datetime-picker',{key:`create-${_vm.key}`,attrs:{"label":"Укажите дату*","text-field-props":{
                'prepend-icon': 'mdi-calendar',
                hint: `Время местное`,
                'persistent-hint': true,
                dense: true,
                outlined: true,
                'error-messages': errors,
              },"time-picker-props":{
                format: '24hr',
              }},model:{value:(_vm.form.achievement_created_limit),callback:function ($$v) {_vm.$set(_vm.form, "achievement_created_limit", $$v)},expression:"form.achievement_created_limit"}},[_c('template',{slot:"dateIcon"},[_c('v-icon',[_vm._v("mdi-calendar-range")])],1),_c('template',{slot:"timeIcon"},[_c('v-icon',[_vm._v("mdi-timer")])],1)],2)]}}])}),_c('p',{staticClass:"mt-6"},[_c('strong',[_vm._v("Дата мероприятий")]),_vm._v(" "),_c('br'),_vm._v(" Калькуляторы будет учитывать достижения, полученные за мероприятия, "),_c('strong',[_vm._v("начавшиеся")]),_vm._v(" не позднее указанной даты ")]),_c('validation-provider',{attrs:{"rules":{
              required: true,
            },"slim":"","name":"event_start_limit"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-datetime-picker',{key:`start-${_vm.key}`,attrs:{"label":"Укажите дату*","text-field-props":{
                'prepend-icon': 'mdi-calendar',
                hint: `Время местное`,
                'persistent-hint': true,
                dense: true,
                outlined: true,
                clearable: true,
                'error-messages': errors,
              },"time-picker-props":{
                format: '24hr',
              }},model:{value:(_vm.form.event_start_limit),callback:function ($$v) {_vm.$set(_vm.form, "event_start_limit", $$v)},expression:"form.event_start_limit"}},[_c('template',{slot:"dateIcon"},[_c('v-icon',[_vm._v("mdi-calendar-range")])],1),_c('template',{slot:"timeIcon"},[_c('v-icon',[_vm._v("mdi-timer")])],1)],2)]}}])})],1)]),(_vm.error)?_c('div',{staticClass:"error--text my-3"},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('v-divider',{staticClass:"my-3"})],1),_c('v-card-actions',{staticClass:"px-5 pb-4"},[(!_vm.isEdit)?[_c('v-btn',{attrs:{"color":"primary","loading":_vm.pending},on:{"click":_vm.handleCreate}},[_vm._v("Создать")]),_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.handleClose}},[_vm._v("Отмена")])]:[_c('v-btn',{attrs:{"color":"primary","loading":_vm.pending},on:{"click":_vm.handleUpdate}},[_vm._v("Сохранить")]),_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.handleClose}},[_vm._v("Закрыть")])]],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }